import { render, staticRenderFns } from "./df-private-area.vue?vue&type=template&id=93c62e0a&"
import script from "./df-private-area.ts?vue&type=script&lang=js&"
export * from "./df-private-area.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports